import { toast } from 'react-hot-toast';
import { AreaType } from 'types/area.types';

import Modal from 'components/common/Modal';
import { patchGeoSpatial } from 'api/area';

interface saveCheckMoadalProps {
  map: google.maps.Map;
  show: boolean;
  areaInfo: AreaType | null;
  changeArea: AreaType | null;
  modalType: string;
  setModalShow: React.Dispatch<React.SetStateAction<boolean>>;
  setAreaInfo: React.Dispatch<React.SetStateAction<AreaType | null>>;
  valueCheck: () => void;
}

function SaveCheckModal({
  map,
  show,
  areaInfo,
  changeArea,
  modalType,
  setModalShow,
  setAreaInfo,
  valueCheck,
}: saveCheckMoadalProps) {
  const handleMove = async () => {
    setModalShow(false);
    if (modalType === 'use') {
      const data = {
        use_yn: false,
      };

      patchGeoSpatial(areaInfo?.id!, data).then((res) => {
        map.data.forEach((i: google.maps.Data.Feature) => {
          if (i.getId() === areaInfo?.id) {
            map.data.remove(i);
          }
        });
        setAreaInfo(null);
        toast.success('구역이 삭제되었습니다.');
      });
    }
  };

  const handleCancel = () => {
    setAreaInfo(null);
    if (modalType === 'use') {
      setAreaInfo(areaInfo);
      valueCheck();
    } else {
      map.data.revertStyle();
      setAreaInfo(changeArea);
    }
    setModalShow(false);
  };

  return (
    <>
      <Modal show={show} setModalShow={setModalShow}>
        <div className="min-w-[400px] p-6 text-center">
          <h3 className="mt-5 mb-5 text-lg font-normal text-gray-500 whitespace-pre-line dark:text-gray-400">
            {modalType === 'use'
              ? '사용하지 않음으로 저장할 경우 구역이 삭제됩니다.\n정말 삭제하시겠습니까?'
              : '변경된 내용이 저장되지 않았습니다. \n 이동하시겠습니까?'}
          </h3>
          <button
            type="button"
            className="px-5 mr-3 font-medium text-center text-white bg-red-500 btn hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800"
            onClick={handleMove}
          >
            네
          </button>
          <button
            type="button"
            className="h-6 px-5 font-medium text-gray-500 bg-white border border-gray-200 btn hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 hover:text-gray-900"
            onClick={handleCancel}
          >
            아니오
          </button>
        </div>
      </Modal>
    </>
  );
}

export default SaveCheckModal;
