import { GrFormPrevious, GrFormNext } from 'react-icons/gr';
import { PaginationProps } from 'types/pagination.types';

export default function Pagination({
  pages,
  goPrev,
  goNext,
  goPageNum,
  lastPage,
  currentPage,
}: PaginationProps) {
  return (
    <div className="flex items-center justify-center w-full mt-5 list-style-none">
      <button
        disabled={currentPage === 1}
        className="relative block rounded bg-transparent px-2 py-1.5 text-neutral-500 transition-all duration-300 text-xl disabled:pointer-events-none hover:bg-neutral-100"
        onClick={goPrev}
      >
        <GrFormPrevious className="icon" />
      </button>
      {pages.map((pageNum) => (
        <button
          key={pageNum}
          aria-current={pageNum === currentPage ? 'page' : undefined}
          onClick={() => goPageNum(pageNum)}
          className="relative block rounded bg-transparent px-3 py-1.5 text-sm text-neutral-600 transition-all duration-300 hover:bg-neutral-100 aria-[current]:bg-neutral-500 aria-[current]:text-neutral-50"
        >
          {pageNum}
        </button>
      ))}

      <button
        disabled={currentPage === lastPage}
        className="relative block rounded bg-transparent px-2 py-1.5 text-neutral-500 transition-all duration-300 text-xl disabled:pointer-events-none hover:bg-neutral-100"
        onClick={goNext}
      >
        <GrFormNext className="icon" />
      </button>
    </div>
  );
}
