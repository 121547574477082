import axios from 'axios';
import { API } from 'config';
import { viewportType } from 'types/area.types';

async function getGeoSpatial(viewportDistance: viewportType) {
  try {
    const url = `${API.GEOSPATIAL_GET}?lng=${viewportDistance.centerLatLng.lng}&lat=${viewportDistance.centerLatLng.lat}&rng=700`;
    const response = await axios.get(url);
    return response.data;
  } catch (err) {
    console.error('get GeoSpatial err', err);
  }
}

async function patchGeoSpatial(featureId: string, patchFeatureData: any) {
  try {
    let data: any = {};

    for (let key in patchFeatureData) {
      if (patchFeatureData[key] !== undefined) {
        data[key] = patchFeatureData[key];
      }
    }

    const response = await axios.patch(`${API.GEOSPATIAL}/${featureId}`, data);
    return response.data;
  } catch (err) {
    console.error('patch feature err', err);
  }
}

async function getFeatureName(geometry: any) {
  try {
    const response = await axios.post(`${API.RECOMMEND_NAME}?q=geometry`, geometry);
    return response.data;
  } catch (err) {
    console.error('get feature err', err);
  }
}

async function putNewPolygon(newPolygon: any) {
  try {
    const response = await axios.put(API.GEOSPATIAL, newPolygon);
    return response.data;
  } catch (err) {
    console.error('add new polygon err', err);
  }
}

async function getTradeHistory() {
  try {
    const response = await axios.get(API.TRADE_HISTORY);
    return response.data;
  } catch (err) {
    console.error('add new polygon err', err);
  }
}


export { getGeoSpatial, patchGeoSpatial, getFeatureName, putNewPolygon, getTradeHistory };
