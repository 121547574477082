import { FormEvent, useState } from 'react';
import Modal from 'components/common/Modal';
import { UserType } from 'types/auth.types';
import { useRecoilValue } from 'recoil';
import { memberListState } from 'atoms/memberStates';
import { patchMember, pwdResetAPI } from 'api/member';
import { toast } from 'react-hot-toast';
import useValid from 'hooks/useVaild';

interface ModalProps {
  userData: UserType | null;
  show: boolean;
  setModalShow: React.Dispatch<React.SetStateAction<any>>;
}

function MemberPasswordEditModal({ userData, show, setModalShow }: ModalProps) {
  const { user, pwdChange, repwdChange, errMsg, isAnyInvalid } = useValid();
  const [resetPwdErrMsg, setResetPwdErrMsg] = useState<string | null>(null);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    const newErrMsg = {
      pwd: errMsg.pwd,
      repwd: errMsg.repwd,
    };

    if (!isAnyInvalid(newErrMsg)) {
      pwdChange(user.password);
      repwdChange(user.rePassword);
    } else {
      pwdResetAPI(userData?.id!, user.password)
        .then((res) => {
          console.log(res);
          setModalShow(false);
          toast.success('정상적으로 변경되었습니다.');
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleCancel = () => {
    setModalShow(false);
  };

  const handleInputFocus = () => {
    setResetPwdErrMsg(null);
  };

  return (
    <Modal show={show} setModalShow={setModalShow}>
      <div className="px-10 py-5 min-w-[450px]">
        <h3 className="text-xl font-bold dark:text-gray-400">회원 비밀번호 수정</h3>
        <div className="line"></div>
        <form onSubmit={handleSubmit}>
          <div className="flex items-center justify-between my-10 text-lg">
            <span>이메일</span>
            <div className="mr-3 area-input-wrap">{userData?.email}</div>
          </div>

          <div className="flex items-center justify-between ">
            <label className="text-lg">비밀번호</label>
            <input
              type="password"
              className={`${
                errMsg.pwd?.valid === false ? 'border-red-400' : ''
              } border-solid py-1.5 px-2 border rounded-lg border-neutral-300 outline-none focus:outline-none max-w-[200px]`}
              onChange={(e) => pwdChange(e.target.value)}
              onFocus={handleInputFocus}
              value={user.password}
              autoComplete="on"
            />
          </div>
          {errMsg.pwd?.valid === false && (
            <p className="mt-1 text-sm text-right text-red-500">{errMsg.pwd.message}</p>
          )}

          <div className="flex items-center justify-between mt-10">
            <label className="text-lg">비밀번호 확인</label>
            <input
              type="password"
              className={`${
                user.password !== user.rePassword && errMsg.repwd?.valid === false
                  ? 'border-red-400'
                  : ''
              } border-solid py-1.5 px-2 border rounded-lg border-neutral-300 outline-none focus:outline-none max-w-[200px]`}
              onChange={(e) => repwdChange(e.target.value)}
              onFocus={handleInputFocus}
              value={user.rePassword}
              autoComplete="on"
            />
          </div>
          {user.password !== user.rePassword && errMsg.repwd?.valid === false ? (
            <p className="mt-1 text-sm text-right text-red-500">{errMsg.repwd.message}</p>
          ) : null}

          <div className="flex justify-between mt-10">
            <button
              type="button"
              className="relative inline-flex items-center justify-center w-1/3 text-base text-red-400 bg-white border-red-300 shadow btn hover:bg-red-500 hover:text-white"
              onClick={handleCancel}
            >
              취소
            </button>
            <button
              type="submit"
              className="relative inline-flex items-center justify-center w-1/3 text-base bg-white shadow btn text-neutral-800 group border-neutral-300 hover:bg-gradient-to-tr hover:from-neutral-900 hover:to-neutral-800 hover:text-white"
            >
              저장
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default MemberPasswordEditModal;
