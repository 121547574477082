import { useState, useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IoSearchSharp } from 'react-icons/io5';
import { useRecoilValue } from 'recoil';
import { memberListState } from 'atoms/memberStates';
import { UserType } from 'types/auth.types';
import { FaAngleDown } from 'react-icons/fa';

export default function MemberFilter() {
  const focusRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchMember, setSearchMember] = useState('');
  const memberList = useRecoilValue(memberListState);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<any>(null);

  const getRoles = (list: UserType[]) => {
    return [...new Set(list.map((data: UserType) => data.role))];
  };

  const roles = getRoles(memberList);

  const handleChangeRole = (value: string) => {
    searchParams.delete('page');
    setSelectedOption(value);
    setIsOpen(false);

    if (value !== 'all') {
      searchParams.set('role', value);
    } else {
      searchParams.delete('role');
    }
    setSearchParams(searchParams);
  };

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    searchParams.delete('page');

    if (searchMember !== '') {
      searchParams.set('email', searchMember);
    } else {
      searchParams.delete('email');
    }
    setSearchParams(searchParams);
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (focusRef.current && !focusRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const clearFilter = () => {
    setSearchMember('');
    setSelectedOption(null)
    navigate('.', { replace: true, state: {} });
  };

  return (
    <div className="flex items-center justify-between mb-3">
      <div className="flex items-center">
        <form
          className="relative flex flex-row flex-wrap items-center w-72"
          onSubmit={handleSearch}
        >
          <div className="join">
            <label htmlFor="search" className="sr-only">
              검색창
            </label>
            <input
              type="text"
              id="search"
              placeholder="이메일 검색"
              className="relative py-2 pl-3 pr-10 text-sm text-gray-600 transition-all bg-white border border-r-0 border-gray-200 rounded shadow outline-none placeholder-neutral-400 join-item w-60 h-9 focus:outline-none focus:border-neutral-300 focus:border-2 focus:border-r-0"
              onChange={(e) => setSearchMember(e.target.value)}
              value={searchMember}
            />
            <button
              type="submit"
              className="top-0 z-10 items-center justify-center p-0 text-base font-normal leading-snug text-center bg-transparent bg-white border border-gray-200 rounded shadow join-item w-9 min-h-fit btn-circle btn right-2 h-9"
            >
              <IoSearchSharp />
            </button>
          </div>
        </form>
        <div className="relative text-sm w-72">
          <button
            className="flex items-center justify-between px-4 py-2 text-sm leading-tight text-gray-700 bg-white border border-gray-200 rounded shadow appearance-none w-72 h-9 focus:outline-none focus:bg-white focus:border-neutral-300 focus:border-2 "
            onClick={() => setIsOpen(!isOpen)}
            aria-haspopup="true"
            aria-expanded="true"
          >
            {selectedOption || <span className="text-neutral-400">권한</span>}
            <FaAngleDown />
          </button>

          {isOpen && (
            <div className="absolute z-10 w-full mt-2 bg-white rounded-md shadow-lg" ref={focusRef}>
              <ul
                className="py-1 overflow-auto text-base rounded-md max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                role="listbox"
                aria-labelledby="listbox-label"
                aria-activedescendant="listbox-option-0"
              >
                <li
                  className="relative py-2 pl-3 text-gray-900 cursor-default select-none pr-9"
                  onClick={() => handleChangeRole('all')}
                  role="option"
                >
                  <span className="block font-normal truncate">{'all'}</span>
                </li>
                {roles.map((option, index) => (
                  <li
                    key={index}
                    className="relative py-2 pl-3 text-gray-900 cursor-default select-none pr-9"
                    onClick={() => handleChangeRole(option)}
                    role="option"
                  >
                    <span className="block font-normal truncate">{option}</span>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
      <button
        type="button"
        className=" ml-3 btn min-h-[2.25rem] h-9 rounded shadow border-gray-200"
        onClick={clearFilter}
      >
        초기화
      </button>
    </div>
  );
}
