const BASE_URL = process.env.REACT_APP_METACITY_API_URL;

const API = {
  SIGNUP: `${BASE_URL}/singup`,
  SIGNIN: `${BASE_URL}/signin`,
  USERS: `${BASE_URL}/api/v1/usesrs/`,
  PASSWORD_RECOVERY: `${BASE_URL}/api/v1/manage/password-recovery`,
  RESET_PASSWORD: `${BASE_URL}/api/v1/manage/reset-password`,
  LOGIN_USER: `${BASE_URL}/api/v1/usesrs/me`,
  GEOSPATIAL_GET: `${BASE_URL}/api/v1/geospatial/feature/search`,
  GEOSPATIAL: `${BASE_URL}/api/v1/geospatial/feature`,
  RECOMMEND_NAME: `${BASE_URL}/api/v1/geospatial/geocoder/geocode/reverse`,
  TRADE_HISTORY: `${BASE_URL}/api/v1/manage/feature/trade/history`,
};

const GOOGLE_MAP = {
  apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  id: process.env.REACT_APP_GOOGLE_MAP_ID,
  initCenter: {
    lat: Number(process.env.REACT_APP_MAP_CENTER_LAT),
    lng: Number(process.env.REACT_APP_MAP_CENTER_LNG),
  },
  loadingTime: Number(process.env.REACT_APP_MAP_TIME),
  animate: {
    tilt: Number(process.env.REACT_APP_MAP_TILT),
    heading: Number(process.env.REACT_APP_MAP_HEADING),
    zoom: Number(process.env.REACT_APP_MAP_ZOOM),
    toTilt: Number(process.env.REACT_APP_MAP_TO_TILT),
    toHeading: Number(process.env.REACT_APP_MAP_TO_HEADING),
    toZoom: Number(process.env.REACT_APP_MAP_TO_ZOOM),
  },
};

export { API, GOOGLE_MAP };
