import { atom } from 'recoil';
import { UserType } from 'types/auth.types';

// 로그인 유저 정보
const authUserStates = atom<UserType | null>({
  key: 'authUserStates',
  default: null,
});

export { authUserStates };
