import { selectAreaState, drawPolygonState } from 'atoms/areaStates';
import { useRecoilValue } from 'recoil';
import { AreaType } from 'types/area.types';
import NewAreaInfo from './NewAreaInfo';
import SelectAreaInfo from './SelectAreaInfo';

function AreaInformation() {
  const areaInfo = useRecoilValue<AreaType | null>(selectAreaState);
  const drawPolygon = useRecoilValue(drawPolygonState);

  return (
    <div className="w-1/3 max-w-[350px] p-3 ml-2 bg-white border rounded shadow h-fit">
      {drawPolygon ? (
        <NewAreaInfo />
      ) : areaInfo && areaInfo ? (
        <SelectAreaInfo />
      ) : (
        <p>구역을 선택해주세요</p>
      )}
    </div>
  );
}

export default AreaInformation;
