import { useState } from 'react';
import Modal from 'components/common/Modal';
import { UserType } from 'types/auth.types';
import { useRecoilValue } from 'recoil';
import { memberListState } from 'atoms/memberStates';
import { patchMember } from 'api/member';
import { toast } from 'react-hot-toast';

interface ModalProps {
  userData: UserType | null;
  show: boolean;
  setModalShow: React.Dispatch<React.SetStateAction<any>>;
}

function MemberEditModal({ userData, show, setModalShow }: ModalProps) {
  const [nicknameValue, setNicknameValue] = useState(userData?.nickname);
  const [roleValue, setRoleValue] = useState(userData?.role);
  const [activeValue, setActiveValue] = useState<boolean | undefined>(userData?.is_active);
  // const [pointValue, setPointValue] = useState<number | undefined>(userData?.balance);
  const memberList = useRecoilValue(memberListState);

  const getRoles = (list: UserType[]) => {
    return [...new Set(list.map((data: UserType) => data.role))];
  };

  const roles = getRoles(memberList);

  const handleNicknameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNicknameValue(e.target.value);
  };

  // const handlePointChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const value: string = e.target.value;
  //   const removedCommaValue: number = Number(value?.replaceAll(',', ''));
  //   setPointValue(removedCommaValue);
  // };

  const handleRoleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setRoleValue(e.target.value);
  };

  const handleActiveChange = () => {
    setActiveValue((prevValue) => !prevValue);
  };

  const handleSave = () => {
    if (!nicknameValue || nicknameValue?.length === 0) {
      toast.error('닉네임을 입력해주세요');
    } else {
      const updatedUser = {
        nickname: nicknameValue,
        role: roleValue,
        is_active: activeValue,
        id: userData?.id,
      } as UserType;

      patchMember(updatedUser).then(() => {
        toast.success('회원정보가 수정되었습니다.');
      });
    }
  };

  const handleCancel = () => {
    setModalShow(false);
    setNicknameValue(userData?.nickname);
    setRoleValue(userData?.role);
    setActiveValue(userData?.is_active);
  };

  return (
    <Modal show={show} setModalShow={setModalShow}>
      <div className="px-7 py-5 min-w-[450px]">
        <h3 className="text-xl font-bold dark:text-gray-400">회원 정보 수정</h3>
        <div className="line"></div>
        <form>
          <div className="flex items-center justify-between my-10 text-lg">
            <span>이메일</span>
            <input
              type="text"
              className="py-1.5 px-2 border rounded-lg max-w-[250px] text-neutral-500"
              value={userData?.email}
              disabled
            />
          </div>
          <label className="flex items-center justify-between my-10 text-lg">
            <span>닉네임</span>
            <input
              type="text"
              className="py-1.5 px-2 border rounded-lg max-w-[250px]"
              value={nicknameValue}
              onChange={handleNicknameChange}
            />
          </label>
          {/* <label className="flex items-center justify-between my-10 text-lg">
            <span>보유포인트</span>
            <div className="mr-3 area-input-wrap">{pointValue ? pointValue.toLocaleString('ko-KR') : 0}</div>
            <input
              type="text"
              className="mr-3 text-right area-input"
              value={pointValue ? pointValue.toLocaleString('ko-KR') : 0}
              onChange={handlePointChange}
            />
          </label> */}
          <label className="flex items-center justify-between py-0 my-10 text-lg">
            <span>권한</span>
            <select
              className="px-2 py-1.5 pr-3 mr-3 border rounded shadow focus:outline-none focus:ring-2 ring-gray-400"
              onChange={(e) => handleRoleChange(e)}
              value={roleValue}
            >
              {roles.map((role, i) => {
                return (
                  <option key={i} value={role}>
                    {role}
                  </option>
                );
              })}
            </select>
          </label>
          <label className="flex items-center justify-between my-10 text-lg">
            <span>상태</span>
            <div className="flex items-center">
              {activeValue === true ? (
                <span className="mr-2 text-sm text-blue-500 text-blod">정상</span>
              ) : (
                <span className="mr-2 text-sm text-red-500 text-blod">비활성</span>
              )}
              <div className="items-center mr-3 area-input-wrap">
                <input
                  type="checkbox"
                  className="area-toogle-slide"
                  checked={activeValue === true}
                  onChange={handleActiveChange}
                />
                <span className="area-toogle-btn" />
              </div>
            </div>
          </label>
          <div className="line"></div>
          <div className="flex justify-between">
            <button
              type="button"
              className="relative inline-flex items-center justify-center w-1/3 text-base text-red-400 bg-white border-red-300 shadow btn hover:bg-red-500 hover:text-white"
              onClick={handleCancel}
            >
              취소
            </button>
            <button
              type="button"
              className="relative inline-flex items-center justify-center w-1/3 text-base bg-white shadow btn text-neutral-800 group border-neutral-300 hover:bg-gradient-to-tr hover:from-neutral-900 hover:to-neutral-800 hover:text-white"
              onClick={handleSave}
            >
              저장
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default MemberEditModal;
