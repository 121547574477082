import MemberFilter from 'components/Member/MemberFilter';
import MemberTable from 'components/Member/MemberTable';
import Layout from 'components/Layout/Layout';

function MemberPage() {
  return (
    <Layout title="회원관리">
      <>
        <MemberFilter />
        <MemberTable />
      </>
    </Layout>
  );
}

export default MemberPage;
