import { useEffect, useState, useCallback, useRef } from 'react';
import { toast } from 'react-hot-toast';
import { mapState, selectAreaState } from 'atoms/areaStates';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AreaType } from 'types/area.types';

import SaveCheckModal from './SaveCheckModal';
import { patchGeoSpatial } from 'api/area';

function SelectAreaInfo() {
  const [areaInfo, setAreaInfo] = useRecoilState<AreaType | null>(selectAreaState);
  const [changeArea, setChangeArea] = useState<AreaType | null>(areaInfo);

  const featureNameRef = useRef<HTMLInputElement | null>(null);
  const [modalShow, setModalShow] = useState(false);
  const [modalType, setModalType] = useState('');
  const [saveCheck, setSaveCheck] = useState(false);

  // 매매여부, 사용여부 값
  const [useValue, setUseValue] = useState<boolean | undefined>(areaInfo?.use_yn);
  const [contractValue, setContractValue] = useState<boolean | undefined>(areaInfo?.contract_yn);

  const [featureName, setFeatureName] = useState<string | undefined>(areaInfo?.feature_name);

  const map = useRecoilValue(mapState);

  const handleUseValueChange = () => {
    setUseValue((prevValue) => !prevValue);
  };

  const handleContractValueChange = () => {
    setContractValue((prevValue) => !prevValue);
  };

  const handleFeatureNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFeatureName(e.target.value);
  };

  async function submitHandler() {
    if (!featureName) {
      toast.error('구역명을 입력해주세요.');
      document.getElementById('_featureName')?.focus();
      return;
    }

    setSaveCheck(false);
    if (areaInfo) {
      try {
        if (areaInfo.use_yn !== useValue) {
          setModalShow(true);
          setModalType('use');
        } else {
          const data = {
            feature_name: featureName,
            contract_yn: contractValue,
            use_yn: useValue,
          };

          console.log(featureName, contractValue, useValue)
          patchGeoSpatial(areaInfo.id, data).then((res) => {
            console.log('수정 후 받는 데이터',res)
            const feature = res?.data.features[0];

            map.data.addGeoJson(feature, { idPropertyName: 'id' });
            setAreaInfo(feature.properties);

            map?.data.overrideStyle(feature, {
              strokeColor: '#EF233C',
              strokeOpacity: 0.5,
              strokeWeight: 2,
              fillColor: '#BEF264',
              fillOpacity: 0.5,
            });

            // setNameEdit(false)
            toast.success('변경이 완료되었습니다.');
          });
        }
      } catch (err) {
        console.log('change area err', err);
      }
    }
  }

  const valueCheck = () => {
    if (areaInfo !== undefined) {
      setUseValue(areaInfo!.use_yn);
      setContractValue(areaInfo!.contract_yn);
      setFeatureName(areaInfo?.feature_name);
    }
  };

  useEffect(() => {
    featureNameRef.current?.focus();
    valueCheck();
    if (saveCheck) {
      setModalType('save');
      setModalShow(true);
      setSaveCheck(false);
    }
  }, [areaInfo]);

  useEffect(() => {
    if (
      useValue !== areaInfo?.use_yn ||
      contractValue !== areaInfo?.contract_yn ||
      featureName !== areaInfo?.feature_name
    ) {
      setSaveCheck(true);
    } else {
      setSaveCheck(false);
    }
  }, [useValue, contractValue, featureName]);

  useEffect(() => {
    if (saveCheck) {
      changeInfo();
    }
  }, [saveCheck]);

  const changeInfo = useCallback(() => {
    setChangeArea(areaInfo);
  }, [useValue, contractValue, featureName]);

  return (
    <>
      <p className="flex items-center text-lg font-bold">
        <input
          id="_featureName"
          type="text"
          className="inline-block max-w-[200px] py-0.5 border-solid border-0 border-b border-neutral-300 outline-none transition focus:outline-none mr-3 focus:border-blue-500 focus:border-b-2"
          value={featureName}
          onChange={handleFeatureNameChange}
          placeholder="구역명을 입력해주세요."
          ref={featureNameRef}
        />
      </p>
      <div className="mt-3 line"></div>
      <form className="flex flex-col">
        <p className="area-label">
          <span>구역 ID</span>
          <span>{areaInfo?.id}</span>
        </p>
        <p className="area-label">
          <span>소유자</span>
          <span>{areaInfo?.nickname}</span>
        </p>
        <p className="area-label">
          <span>매입가</span>
          <span>{areaInfo && areaInfo?.price && areaInfo?.price.toLocaleString('ko-KR')}</span>
        </p>

        <div className="area-label">
          <div className="tooltip tooltip-right" data-tip="소유자 설정 매매여부">
            <span className='mr-1.5'>구역상태</span>
          </div>
          <div className="items-center area-input-wrap">
          {areaInfo?.is_sale === true ? (<span className='text-green-500'>매매가능</span>) : (<span className='text-red-500'>매매불가</span>)}
          </div>
        </div>

        <div className="line"></div>
        <p className="area-label">
          <span>생성자</span>
          <span>{areaInfo?.created_by}</span>
        </p>
        <label className="area-label">
          <span>사용여부</span>
          <div className="items-center area-input-wrap">
            <input
              type="checkbox"
              className="area-toogle-slide"
              checked={useValue === true}
              onChange={handleUseValueChange}
            />
            <span className="area-toogle-btn" />
          </div>
        </label>
        <label className="area-label">
          <span>매매제한/해제</span>
          <div className="items-center area-input-wrap">
            <input
              type="checkbox"
              className="area-toogle-slide"
              checked={contractValue === true}
              onChange={handleContractValueChange}
            />
            <span className="area-toogle-btn" />
          </div>
        </label>
        <div className="area-label">
          <span>최근 작업자</span>
          <p className="area-input-wrap">
            <span>{areaInfo?.updated_by}</span>
          </p>
        </div>
        <div className="area-label">
          <span>최근 작업일</span>
          <p className="area-input-wrap">
            <span>{areaInfo?.updated_at ? new Date(areaInfo?.updated_at).toDateString() : ''}</span>
          </p>
        </div>
        <div className="mb-4 line"></div>
        <div className="flex items-center justify-end">
          <button
            type="button"
            disabled={!saveCheck}
            className={`px-7 py-1 text-base ${
              !saveCheck
                ? 'text-neutral-100 btn pointer-events-none'
                : 'btn bg-white shadow text-neutral-800 group border-neutral-300 hover:bg-gradient-to-tr hover:from-neutral-900 hover:to-neutral-800 hover:text-white'
            }`}
            onClick={submitHandler}
          >
            저장
          </button>
        </div>
      </form>
      <SaveCheckModal
        map={map}
        show={modalShow}
        areaInfo={areaInfo}
        changeArea={changeArea}
        modalType={modalType}
        setModalShow={setModalShow}
        setAreaInfo={setAreaInfo}
        valueCheck={valueCheck}
      />
    </>
  );
}

export default SelectAreaInfo;
