import { atom } from 'recoil';
import { AreaType, viewportType } from 'types/area.types';
import { GOOGLE_MAP } from 'config';

const mapState = atom<any>({
  key: 'mapState',
  default: undefined,
  dangerouslyAllowMutability: true,
});

const viewportState = atom<viewportType | null>({
  key: 'viewportState',
  default: {
    centerLatLng: { lat: GOOGLE_MAP.initCenter.lat, lng: GOOGLE_MAP.initCenter.lng },
    range: 700,
  },
});

const selectAreaState = atom<AreaType | null>({
  key: 'selectAreaState',
  default: null,
});

const drawPolygonState = atom<google.maps.Polygon | null>({
  key: 'drawPolygonState',
  default: null,
  dangerouslyAllowMutability: true,
});

export { mapState, viewportState, selectAreaState, drawPolygonState };
