import { Wrapper, Status } from '@googlemaps/react-wrapper';

import Map from 'components/Area/Map';
import AreaInformation from 'components/Area/AreaInformation';
import Layout from 'components/Layout/Layout';

function AreaPage() {
  const apiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY as string;

  const render = (status: Status) => {
    return <h1>{status}</h1>;
  };

  return (
    <Layout title="구역관리">
      <div className="flex">
        <Wrapper apiKey={apiKey} render={render} libraries={['drawing']}>
          <Map />
        </Wrapper>
        <AreaInformation />
      </div>
    </Layout>
  );
}

export default AreaPage;
