import { useState } from 'react';
import { ValidationResult } from 'types/auth.types';

const EMAIL_CHECK = /^[a-z0-9\.\-_]+@([a-z0-9\-]+\.)+[a-z]{2,6}$/;
const PASSWORD_CHECK = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/;

const emailValidation = (email: string): ValidationResult => {
  if (email.length === 0) {
    return { valid: false, message: '이메일을 입력해 주세요' };
  } else if (!EMAIL_CHECK.test(email)) {
    return { valid: false, message: '이메일 형식으로 입력해주세요' };
  } else return { valid: true, message: '' };
};

const nicknameValidation = (nickname: string): ValidationResult => {
  if (nickname.length === 0) {
    return { valid: false, message: '닉네임을 입력해주세요' };
  } else if (nickname.length < 2) {
    return { valid: false, message: '닉네임은 두글자 이상 입력해주세요' };
  } else return { valid: true, message: '' };
};

const pwdValidation = (pwd: string): ValidationResult => {
  if (pwd.length === 0) {
    return { valid: false, message: '비밀번호를 입력해주세요' };
  } else return { valid: true, message: '' };

  // if(!PASSWORD_CHECK.test(pwd)) {
  //   return { valid: false, message: '문자/숫자/특수문자 포함 8~20자 사이로 입력해주세요' };
  // }
};

const rePwdValidation = (pwd: string, repwd: string): ValidationResult => {
  if (repwd.length === 0) {
    return { valid: false, message: '비밀번호를 입력해주세요' };
  } else if (pwd !== repwd) {
    return { valid: false, message: '비밀번호가 일치하지 않습니다.' };
  } else return { valid: true, message: '' };
};

export default function useValid() {
  const [email, setEmail] = useState<string>('');
  const [nickname, setNickname] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [rePassword, setRePassword] = useState<string>('');
  const [emailErrMsg, setEmailErrMsg] = useState<null | ValidationResult>(null);
  const [nicknameErrMsg, setNicknameErrMsg] = useState<null | ValidationResult>(null);
  const [pwdErrMsg, setPwdErrMsg] = useState<null | ValidationResult>(null);
  const [rePwdErrMsg, setRePwdErrMsg] = useState<null | ValidationResult>(null);

  const emailChange = (value: string) => {
    const emailCheck = emailValidation(value);

    setEmail(value);
    setEmailErrMsg(emailCheck);
  };

  const nicknameChange = (value: string) => {
    const nicknameCheck = nicknameValidation(value);

    setNickname(value);
    setNicknameErrMsg(nicknameCheck);
  };

  const pwdChange = (value: string) => {
    const pwdCheck = pwdValidation(value);

    setPassword(value);
    setPwdErrMsg(pwdCheck);
  };

  const repwdChange = (value: string) => {
    const rePwdCheck = rePwdValidation(password, value);

    setRePassword(value);
    setRePwdErrMsg(rePwdCheck);
  };


  const isAnyInvalid = (obj: any) => {
    for (let key in obj) {
      if (obj[key] === null || obj[key].valid === false) {
        return false;
      }
    }
    return true;
  };

  const user = { email: email, nickname: nickname, password: password, rePassword: rePassword };
  const errMsg = {
    email: emailErrMsg,
    nickname: nicknameErrMsg,
    pwd: pwdErrMsg,
    repwd: rePwdErrMsg,
  };

  return { user, emailChange, nicknameChange, pwdChange, repwdChange, errMsg, isAnyInvalid };
}
