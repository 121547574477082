import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { API } from 'config';
import { AuthRequest, UserType } from 'types/auth.types';
import { getCookie, setCookie, removeCookie } from 'utils/cookie';

// API 요청 시 항상 실행될 인터셉터
axios.interceptors.request.use(
  (config) => {
    const token = getCookie('token')?.replace(/"/g, '');

    // token 있을 경우 요청 헤더에 추가
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    const navigate = useNavigate();
    if (error.response.status === 401) {
      navigate('/login');
      removeCookie('token');
    }
    return Promise.reject(error);
  }
);

async function signin({ email, password }: AuthRequest): Promise<UserType> {
  try {
    const formData = new FormData();

    formData.append('username', email);
    formData.append('password', password);

    const res = await axios.post(API.SIGNIN, formData);

    const accessToken = res.data.access_token;
    setCookie('token', accessToken, { path: '/', maxAge: 3600 });

    return accessToken;
  } catch (err) {
    console.log('login err', err);
    throw err;
  }
}

async function getUser() {
  try {
    const res = await axios.get(API.LOGIN_USER);
    return res;
  } catch (err) {
    console.error(err);
    throw err;
  }
}

export { signin, getUser };
